import { useRouter } from "next/router";
import { useEffect } from "react";
import { adminRoutes } from "@constants/routes";

const NotFoundPage = () => {
  const router = useRouter();

  useEffect(() => {
    router.push(adminRoutes.home);
  }, []);

  return <></>;
};

export default NotFoundPage;
